import React, { FC } from 'react';
import styled from 'styled-components';
import ReviewCard from 'components/reviews/ReviewCard';
import TrustpilotStars from 'components/reviews/TrustpilotStars';
import TrustpilotStarsPartial from 'components/reviews/TrustpilotStarsPartial';
import CtaButton from 'components/reviews/CtaButton';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';
import { tablet } from 'styles/breakpoints';

const MainReviewSection: FC = () => (
  <StyledSection
    backgroundColor="#E9F6F2"
    sectionPadding="56px 16px"
    marginTop="69px"
  >
    <SectionHeading marginBottom="8px">
      Effecto review highlights
    </SectionHeading>
    <HappyUsersContainer>
      <SectionSubheading marginBottom="10px">
        Thousands of happy users around the world
      </SectionSubheading>
      <RatingContainer>
        <StarContainer>
          <TrustpilotStars starCount={4} />
          <TrustpilotStarsPartial starCount={1} />
        </StarContainer>
        <RatingNumber>4.8</RatingNumber>
      </RatingContainer>
    </HappyUsersContainer>
    <div>
      <ReviewCard />
      <CtaButton buttonText="Start your journey" />
    </div>
  </StyledSection>
);

export default MainReviewSection;

const HappyUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 38px;

  @media ${tablet} {
    margin-bottom: 20px;
  }

  img {
    width: 125px;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StarContainer = styled.div`
  display: flex;
  gap: 6.579px;
`;

const RatingNumber = styled.span`
  font-size: 12px;
  line-height: 21.7px;
  color: #000;
`;
